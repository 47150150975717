<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 63 74"
  >
    <path
      fill="currentColor"
      d="M40.3 49.3A149.6 149.6 0 0 1 37.4 70c-.6 2.4-2.6 3.6-6.1 3.6-3.5 0-5.6-1.2-6.2-3.6a149.5 149.5 0 0 1-2.9-20.7c0-4.6 3-6.8 9-6.8s9 2.2 9 6.8ZM62 32a31.7 31.7 0 0 1-19.4 29c-.3 0-.5 0-.7-.2a.7.7 0 0 1-.2-.6 159.9 159.9 0 0 1 1-5A26 26 0 0 0 56.9 32 25.6 25.6 0 0 0 43.3 9a25 25 0 0 0-23-.5 25.6 25.6 0 0 0-13 14A25.2 25.2 0 0 0 9.4 46c2.6 4 6 7.2 10.4 9.3a106.1 106.1 0 0 1 .8 5.7c-.3.2-.5.2-.7.1A30.8 30.8 0 0 1 .3 30.5a30.5 30.5 0 0 1 8.9-20.3 31.5 31.5 0 0 1 20-9.2 30.4 30.4 0 0 1 23.2 8.4A31.7 31.7 0 0 1 62.1 32Zm-21.8-1.3c0 2.5-.9 4.6-2.7 6.4a8.7 8.7 0 0 1-6.3 2.7 9 9 0 0 1-6.4-2.7 8.8 8.8 0 0 1-2.7-6.4c0-2.5 1-4.7 2.7-6.5a8.7 8.7 0 0 1 6.4-2.6c2.5 0 4.6.9 6.3 2.6a9 9 0 0 1 2.7 6.5ZM51.9 32a20.5 20.5 0 0 1-8 16.5h-.7a.8.8 0 0 1-.4-.5 9.7 9.7 0 0 0-1.2-3.8c-.2-.2-.1-.5.1-.8a16 16 0 0 0 5-11.4A15.3 15.3 0 0 0 38 18a15.2 15.2 0 0 0-17.7 3c-2.6 2.7-4 5.8-4.4 9.4a15.8 15.8 0 0 0 5 13c.2.3.2.6 0 .8-.6 1.1-1 2.4-1.1 3.8-.1.3-.2.4-.4.5h-.7a21 21 0 0 1-5.3-26.9 20.9 20.9 0 0 1 16.9-10.4A20.4 20.4 0 0 1 51.9 32Z"
    />
  </svg>
</template>
